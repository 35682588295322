import Request from "../@api/request";

const baseURL = "https://back.transfarmer.kr/";
// const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

export default class BoardService {
  static WriteInuqiry = async (data: any) => {
    const result = await request.post("inquiry", data);

    return result;
  };
}
